import React from 'react';

const NotFoundPage = () => (
  <>
    <h1>NOT FOUND</h1>
    <p>Hier gibt es leider nichts.</p>
  </>
);

export default NotFoundPage;
